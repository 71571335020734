
import React from 'react';
import "../../regular.css"
import { SEARCH_API } from './../../services/Api/searching';
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import PageHeader from '../../components/PageHeader/PageHeader';
import { AUTENTICATE_API } from '../../services/Api/authenticationApi';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import ToggleSwitch from '../../components/common/ToggleSwitch';
import Switch from "react-switch";

class AccountInformation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            planDetails: "",
            userInfo: '',
            validity: "",
            topUpPlane: [],
            userDetails: "",
            userName: '',
            created: '',
            pharm_class: 0,
            thera_class: 0,
            routes: 0,
            dose_forms: 0,
            natural_products: 0,
            renal_warning: 0,
            protein_binding: 0,
            dialysis: 0,
            hepatic_warning: 0,
            pregnancy_warnings: 0,
            lactation_warnings: 0,
            oral_adverse_effects: 0,
            lab_parameters: 0,
            cross_references: 0,
            citations: 0,
            viewList: '',
            count: 0,
            c_id: "",
            modalIsOpen: false,
            validateEmail: false,
            newEmail: '',
            old_password: "",
            new_password: '',
            modalIsOpenPassword: false,
            errMsg: '',
            verificationCode: '',

            checked: false,


            "PharmClassImg": "",
            "TheraClassImg": "",
            "RoutesImg": "",
            "DoseFormsImg": "",
            "HowtoTakeImg": "",
            "DietImg": "",
            "GrapefruitInteractionsImg": "",
            "AlcoholInteractionsImg": "",
            "NaturalproductinteractionsImg": "",
            "WarningsImg": "",
            "RenalwarningsImg": "",
            "ProteinbindingImg": "",
            "DialysiswarningsImg": "",
            "HepaticwarningsImg": "",
            "PregnancywarningsImg": "",
            "BreastfeedingWarningsImg": "",
            "OralAdverseEffectsImg": "",
            "GIAdverseEffectsImg": "",
            "NutritionalAdverseEffectsImg": "",
            "OtherAdverseEffectsImg": "",
            "LabParametersImg": "",
            "MonitoringImg": "",
            "CrossReferenceImg": "",
            "CitationsImg": "",
            "UsesImg": '',
            'LactationImg': '',
            'AdverseImg': ''

        }

        this.planType = [{ 2: 'Yearly', 1: 'Monthly' }];
        this.myRef = React.createRef();
        this.myRef1 = React.createRef();
        this.customStyles = {
            content: {
                top: '30%',
                left: '30%',
                right: 'auto',
                bottom: 'auto',
                // marginLefts: '-18%',
                transform: 'translate(-50%, -50%)',
                width: '560px',
                background: '#ECEBEB',
                border: '1px solid #ECEBEB',
                borderRadius: '0px',
                zIndex: 1000
            },
        };
    }
    componentDidMount() {
        if (localStorage.getItem("setUser") != null) {
            if (this.state.userInfo?.country_view_id == 1) {
                setTimeout(() => {
                    document.getElementById('usId').checked = true
                }, 500)
            }
            if (this.state.userInfo?.country_view_id == 2) {
                setTimeout(() => {
                    document.getElementById('canadaId').checked = true
                }, 500)
            }
            let By = localStorage.getItem("setUser");
            By = JSON.parse(By);
            this.setState({ userInfo: By?.userInfo }, () => {
                this.getUserDetails(this.state.userInfo?.user_id);
            })
        }
    }

    getValidate(e) {
        var emailfilter = /^[\w._-]+[+]?[\w._-]+@[\w.-]+\.[a-zA-Z]{2,6}$/;
        if (this.state.newEmail != "") {
            if ((!(emailfilter.test(this.state.newEmail)))) {
                this.setState({ errMsg: "Please enter the valid email" })
                setTimeout(() => {
                    this.setState({ errMsg: "" })
                }, 2000)
                return false;
            }
            let obj = {
                email_id: this.state.newEmail,
                password: '',
                user_id: this.state.userInfo.user_id
            }
            AUTENTICATE_API?.update_EmailOrPassword(obj).then(res => {
                if (res && res?.status == 201) {
                    toast.success(res?.message, { autoClose: 1000, position: toast.POSITION.TOP_RIGHT });
                    setTimeout(() => {
                        this.setState({ validateEmail: true });
                        this.setState({ modalIsOpenPassword: false, new_password: '', old_password: '' })
                    }, 1000)
                }
            });
        } else {
            this.setState({ errMsg: 'Complete the required fields*' });
            setTimeout(() => {
                this.setState({ errMsg: '' })
            }, 2000)
        }
    }

    validateCode(e) {
        let obj = {
            email_id: this.state.newEmail,
            verify_otp: this.state.verificationCode,
            user_id: this.state.userInfo.user_id
        }
        AUTENTICATE_API?.Verified_emailUpdate(obj).then(res => {
            if (res && res?.status == 200) {
                let setUser = {
                    userInfo: res.data,
                    token: res?.token
                }
                localStorage.setItem('setUser', JSON.stringify(setUser))
                toast.success(res?.message, { autoClose: 1000, position: toast.POSITION.TOP_RIGHT });
                this.setState({ validateEmail: false, validateEmail: false, modalIsOpen: false, verificationCode: '' })
            }
        });
    }

    changePaaasword(e) {
        let obj = {
            "password": this.state.new_password,
            "old_password": this.state.old_password,
            "email_id": this.state.userInfo.email_id
        }
        if (obj.old_password == obj?.password) {
            toast.error('Old password and new password cannot be same', { autoClose: 1000, position: toast.POSITION.TOP_RIGHT });
            return false;
        }
        if (obj?.password == "" || obj.old_password == '' || obj.email_id == "") {
            this.setState({ errMsg: 'Complete the required fields*' });
            setTimeout(() => {
                this.setState({ errMsg: '' })
            }, 2000)
        } else {
            AUTENTICATE_API?.update_EmailOrPassword(obj).then(res => {
                if (res && res?.status == 200) {
                    toast.success(res?.message, { autoClose: 1000, position: toast.POSITION.TOP_RIGHT });
                    setTimeout(() => {
                        this.setState({ modalIsOpenPassword: false, new_password: '', old_password: '' })
                    }, 1000)
                }
            });
        }
    }

    getModuleByUser(id) {
        SEARCH_API?.getModuleByUser(id).then(res => {
            if (res && res?.count) {
                this.setState({ count: res?.count })
            }
        })
    }


    getUserDetails(id) {
        SEARCH_API?.getUserDetails(id).then(res => {
            if (res && res?.data) {
                this.getUserPlanDetails(this.state.userInfo?.user_id);
                this.getModuleByUser(this.state.userInfo?.user_id);
                this.setState({
                    userDetails: res?.data[0],
                    optNames: res?.opt_name.map(opt => opt.opt_name).join(', ')
                }, () => {
                    const str2 = this.state.userDetails?.first_name.charAt(0).toUpperCase() + this.state.userDetails?.first_name.slice(1);
                    this.setState({ userName: str2 }, () => {
                        if (this.state.userInfo?.country_view_id == 1) {
                            setTimeout(() => {
                                document.getElementById('usId').checked = true
                            }, 500)
                        }
                        if (this.state.userInfo?.country_view_id == 2) {
                            setTimeout(() => {
                                document.getElementById('canadaId').checked = true
                            }, 500)
                        }
                    })
                })
            }
        })
    }

    handleChangeToggle(checked) {
        this.setState({ checked });
    }

    getUserPlanDetails(id) {
        SEARCH_API?.getUserPlan(id).then(res => {
            if (res && res?.data) {
                this.setState({ planDetails: res?.data[0] }, () => {
                    console.log("planDetails", this.state.planDetails)
                    this.setState({
                        topUpPlane: this.state.planDetails?.topup_plan_id?.map(val => {
                            return val?.module_name
                        })
                    }, () => {
                        console.log("topUpPlane", this.state.topUpPlane)
                    })
                    var d = this.state.planDetails?.created_dt
                    var dArray = d.split('/')
                    var yr = dArray[0];
                    var month = dArray[1];
                    var day = dArray[2]
                    this.setState({ created: month + "/" + day + "/" + yr })

                    if (this.state.planDetails?.plan_type == 2) {
                        yr = 1 + parseInt(yr)
                        this.setState({ validity: month + "/" + day + "/" + yr })
                    } else {
                        month = 1 + parseInt(month)
                        if (month?.length == 1) {
                        } else {
                            month = month < 10 ? "0" + (month) : month
                            this.setState({ validity: month + "/" + day + "/" + yr })
                        }
                    }
                })
            }
        })
    }

    onChangeRadio(data, e) {
        this.setState({ c_id: parseInt(e?.target.value) })
    }

    updateCounty(e) {
        e?.preventDefault()
        if (this.state.c_id != "") {
            SEARCH_API?.updatePreferredCountry(this.state.userInfo?.user_id, this.state.c_id).then(res => {
                if (res) {
                    toast.success(`Preferred country updated successfully.`, { autoClose: 1000, position: toast.POSITION.TOP_RIGHT });
                    let stateData = localStorage.getItem('setUser');
                    let stateDataParse = JSON.parse(stateData);
                    stateDataParse.userInfo.country_view_id = this.state.c_id
                    let jsonStr = JSON.stringify(stateDataParse)
                    setTimeout(() => {
                        localStorage.setItem("setUser", jsonStr);
                    }, 1000)
                }
            })
        }
    }

    render() {
        return <>
            <PageHeader style={{ marginTop: '50px', height: '20px', marginTop: '50px' }}
                bgimg="https://www.truthaboutweight.global/shared/content-pages/hero-images/support-section/bmi_calculator_hero.jpg"
                title="DNI Account Information"
                description={""} />
            <section className="section py-1  novi-background bg-cover">
                <div className="container">
                    {/* <h2 >DNI Account Information</h2> */}
                    <div className='row account mb-4 mt-4'>
                        <div className='col-lg-12' style={{ margin: 'auto' }}>
                            <div className='bg-white shadow p-4'>
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <a className="nav-link active" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="true" >User Information</a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a className="nav-link" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" false="false">Plans</a>
                                    </li>
                                </ul>
                                <div className="tab-content pt-4" id="myTabContent">
                                    <div className="tab-pane fade show" id="home" role="tabpanel" aria-labelledby="home-tab">
                                        <div className="col-10" style={{ textAlign: 'initial', margin: 'auto' }}>
                                            <div className='card-header'>
                                                <h5 style={{ textAlign: 'center', background: '#A7CA56', color: 'whitesmoke' }}>Plan Information</h5>
                                                {this.state.planDetails != '' ? <>
                                                    <div className='row'>
                                                        <div className='col-md-3' id="keys">
                                                            Plan Name
                                                        </div>
                                                        <div className='col-md-2' id="keys">
                                                            :
                                                        </div>
                                                        <div className='col-md-5' id="vals">
                                                            {this.state.planDetails?.plan_name} {this.state.planDetails?.plan_name == 'Student' ? <span>
                                                                (HIV, Oncology)
                                                            </span> : ""}
                                                        </div>
                                                    </div>
                                                    {this.state.topUpPlane?.length == 0 ? '' : <>
                                                        <div className='row'>
                                                            <div className='col-md-3' id="keys">
                                                                Topup Modules
                                                            </div>
                                                            <div className='col-md-2' id="keys">
                                                                :
                                                            </div>
                                                            <div className='col-md-7' id="vals">
                                                                {this.state.topUpPlane.toString()}&nbsp;
                                                                {
                                                                    this.state.count == 0 ? '' : <><span style={{ fontSize: "14px", height: "30px", paddingTop: "8px", cursor: 'pointer', color: 'whitesmoke', }} className='badge badge-success' onClick={(e) => { window.location.href = `#/account/add-on-modules/${this.state.userDetails.plan_detail_id}` }} >Upgrade plan</span> </>
                                                                }
                                                            </div>
                                                        </div>
                                                    </>}

                                                    <div className='row'>
                                                        <div className='col-md-3' id="keys">
                                                            Plan Type
                                                        </div>
                                                        <div className='col-md-2' id="keys">
                                                            :
                                                        </div>
                                                        <div className='col-md-5' id="vals">
                                                            {this.planType[0][this.state.planDetails?.plan_type]}
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-md-3' id="keys">
                                                            Plan Description
                                                        </div>
                                                        <div className='col-md-2' id="keys">
                                                            :
                                                        </div>
                                                        <div className='col-md-5' id="vals">
                                                            {this.state.planDetails?.description}
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-md-3' id="keys">
                                                            Plan Price
                                                        </div>
                                                        <div className='col-md-2' id="keys">
                                                            :
                                                        </div>
                                                        <div className='col-md-5' id="vals">
                                                            {this.state.userInfo?.is_free==1?"Free":<>
                                                                ${this.state.userInfo?.country_view_id === 2 ? this.state.planDetails?.plan_price_can : this.state.planDetails?.plan_price_us} {this.state.userInfo?.country_view_id == 2 ? 'CAD' : "USD"}
                                                            </>}
                                                            
                                                        </div>
                                                    </div>
                                                    {this.state.userInfo?.is_free==1?"":<div className='row'>
                                                        <div className='col-md-3' id="keys">
                                                            Subscription Date
                                                        </div>
                                                        <div className='col-md-2' id="keys">
                                                            :
                                                        </div>
                                                        <div className='col-md-5' id="vals">
                                                            From &nbsp;&nbsp;<span style={{ textDecoration: 'underline', color: '#8080ef' }}>{this.state.created}</span>&nbsp;&nbsp; To &nbsp;<span style={{ textDecoration: 'underline', color: '#8080ef' }}>{this.state.validity}</span>
                                                        </div>
                                                    </div>}
                                                    
                                                </> : <h5 style={{ margin: 'auto', textAlign: 'center', paddingTop: 10 }}>No record found !</h5>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade show active" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                        <div className="col-10" style={{ textAlign: 'initial', margin: 'auto' }}>
                                            <div className='card-header'>
                                                <h5 style={{ textAlign: 'center', background: '#A7CA56', color: 'whitesmoke' }}>User Information</h5>
                                                {(this.state.userDetails == "" || this.state.userDetails === undefined) ? <h5 style={{ margin: 'auto', textAlign: 'center', paddingTop: 10 }}>No record found !</h5> : <><div className='row'>
                                                    <div className='col-md-3' id="keys">
                                                        Name
                                                    </div>
                                                    <div className='col-md-1' id="keys">
                                                        :
                                                    </div>
                                                    <div className='col-md-6' id="vals">
                                                        {this.state.userName + " " + this.state.userDetails?.last_name}
                                                    </div>
                                                </div>
                                                    <div className='row'>
                                                        <div className='col-md-3' id="keys">
                                                            Email
                                                        </div>
                                                        <div className='col-md-1' id="keys">
                                                            :
                                                        </div>
                                                        <div className='col-md-6' id="vals">
                                                            {this.state.userDetails?.email_id}
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-md-3' id="keys">
                                                            Profession
                                                        </div>
                                                        <div className='col-md-1' id="keys">
                                                            :
                                                        </div>
                                                        <div className='col-md-6' id="vals">
                                                            {this.state.userDetails?.profession_text}
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-md-3' id="keys">
                                                            Country
                                                        </div>
                                                        <div className='col-md-1' id="keys">
                                                            :
                                                        </div>
                                                        <div className='col-md-6' id="vals">
                                                            {this.state.userDetails?.country_name}
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-md-3' id="keys">
                                                            Organization
                                                        </div>
                                                        <div className='col-md-1' id="keys">
                                                            :
                                                        </div>
                                                        <div className='col-md-6' id="vals">
                                                            {this.state.userInfo.organization != null ? this.state.userInfo.organization : 'NA'}
                                                        </div>
                                                    </div>
                                                    {/* optName row */}
                                                    <div className='row'>
                                                        <div className='col-md-3' id="keys">
                                                            Area of Interest
                                                        </div>
                                                        <div className='col-md-1' id="keys">
                                                            :
                                                        </div>
                                                        <div className='col-md-6' id="vals">
                                                            {this.state.optNames}
                                                        </div>
                                                    </div>

                                                    <hr style={{
                                                        marginTop: 15,
                                                        marginBottom: 0,
                                                        borderTop: '1px solid #A7CA56',
                                                    }} />
                                                    <div className='row'>
                                                        <div className='col-md-12' id="keys">
                                                            <span id="edit-emails" onClick={() => { this.setState({ modalIsOpenPassword: true }) }}>Change Password</span>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;<span id="edit-emails" onClick={() => { this.setState({ modalIsOpen: true }) }}>Update Email</span>
                                                        </div>
                                                    </div>
                                                </>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Modal
                                    isOpen={this.state.modalIsOpen}
                                    style={this.customStyles}
                                    contentLabel="Example Modal"
                                >
                                    <div className='row'>
                                        {this.state.validateEmail == false ? <div className='col-md-12' style={{}}>
                                            <h3>Change Your Email</h3>
                                            <label className='ml-1 mt-0'><strong>Email <span style={{ color: 'red' }}>*</span></strong></label>
                                            <input type='text' value={this.state.newEmail} onChange={(e) => {
                                                this.setState({ newEmail: e?.target?.value })
                                            }} placeholder='Enter Your New Email' className='form-control' />
                                            <button className='btn btn-success mt-3' onClick={this.getValidate.bind(this)}>Validate Email</button>
                                            <button className='btn btn-danger mt-3 ml-2' onClick={() => {
                                                this.setState({ modalIsOpen: false, validateEmail: false })
                                            }}>Cancel</button><span style={{ color: '#ef3405', display: 'block', marginTop: 2, marginLefts: 10 }}>{this.state.errMsg ? this.state.errMsg : ''}</span>
                                        </div> : <div className='col-md-12' style={{}}>
                                            <h3>Email Verification</h3>
                                            <label className='ml-1 mt-0'><strong>Verification Code <span style={{ color: 'red' }}>*</span></strong></label>
                                            <input type='text' value={this.state.verificationCode} onChange={(e) => {
                                                this.setState({ verificationCode: e.target.value })
                                            }} placeholder='Enter Verification Code' className='form-control' />
                                            <button className='btn btn-success mt-3' onClick={this.validateCode.bind(this)}>Validate</button>
                                            <button className='btn btn-danger mt-3 ml-2' onClick={() => {
                                                this.setState({ modalIsOpen: false, validateEmail: false })
                                            }}>Cancel</button><span style={{ color: '#ef3405', display: 'block', marginTop: 2, marginLefts: 10 }}>{this.state.errMsg ? this.state.errMsg : ''}</span>
                                        </div>}

                                    </div>
                                </Modal>
                                <Modal
                                    isOpen={this.state.modalIsOpenPassword}
                                    style={this.customStyles}
                                    contentLabel="Example Modal"
                                >
                                    <div className='row'>
                                        <div className='col-md-12' style={{}}>
                                            <h3>Change Your Password</h3>
                                            <label className='ml-1 mt-0'><strong>Old Password <span style={{ color: 'red' }}>*</span></strong></label>
                                            <input type='password' maxLength={12} value={this.state.old_password} onChange={(e) => {
                                                this.setState({ old_password: e?.target?.value })
                                            }} style={{ marginTop: '-8px' }} placeholder='Enter Old Password' className='form-control' /><br />
                                            <label><strong>New Password <span style={{ color: 'red' }}>*</span> </strong></label>
                                            <input type='password' maxLength={12} value={this.state.new_password} onChange={(e) => {
                                                this.setState({ new_password: e?.target?.value })
                                            }} style={{ marginTop: '-8px' }} placeholder='Enter New Password' className='form-control' />
                                            <button className='btn btn-success mt-3' onClick={this.changePaaasword.bind(this)}>Update</button>
                                            <button className='btn btn-danger mt-3 ml-2' onClick={() => {
                                                this.setState({ modalIsOpenPassword: false })
                                            }}>Cancel</button> <span style={{ color: '#ef3405', display: 'block', marginTop: 2, marginLefts: 10 }}>{this.state.errMsg ? this.state.errMsg : ''}</span>
                                        </div>
                                    </div>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    }
}

export default AccountInformation