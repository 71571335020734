import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { DummyImage } from "react-simple-placeholder-image";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import { PLAN_SELECTION } from "../../services/Api/PlanselectionApi";
let planId = "";
//  let planPrice="";
const ModuleSelection = () => {
  const reloadCountMS = Number(sessionStorage.getItem('reloadCountMS')) || 0;
  const [includeplanList, setIncludePlanList] = useState([]);
  let userDetail = JSON.parse(localStorage.getItem('setUser'));
  const [countryId, setcountryid] = useState();
  const [availableplanList, setavailablePlanList] = useState([]);
  const [message, setMessage] = useState("")
  const [addModuleList, setaddModuleList] = useState("");
  const [planSelectionList, setPlanSelectionList] = useState([])
  const [test, setTest] = useState([]);
  const [addIDS, setaddIDS] = useState([]);
  const [planPrice, setPlanPrice] = useState(0);
  const [UpplanPrice, setUpdatePrice] = useState(0);
  const [desc, setDesc] = useState('');


  const [selectedTax,setSelectedTax]=useState(0);
  const [taxInValue,setTaxInValue]=useState(0);
  const [p_name,set_p_name]=useState('')

  const mn="Student";
  const mn1="Main"
  setDesc
  let id = useParams();
  planId = id?.id
  useEffect(() => {
    if (reloadCountMS<=0) {
      // window.location.reload();
      PLAN_SELECTION.availablePlanList(planId).then((response) => {
        const filteredData = response?.data.filter(module => module.module_name !== "Oncology1");
        setavailablePlanList(filteredData);
      });
      
      PLAN_SELECTION.includePlanList(planId).then((response) => {
        debugger
      
        const filteredData = response?.data.filter(module => module.module_name !== "Oncology1");
        set_p_name(response?.plan_name);
        setIncludePlanList(filteredData);
        setMessage(response?.plan_name);
        setDesc(response?.description);
      });
      setcountryid(userDetail?.userInfo?.country_id);
      PLAN_SELECTION.PlanSelectionList().then((response) => {
        setPlanSelectionList(response?.data);
        setTest(response?.data.filter((item) => item.plan_id.toString() === planId).map((plan) => {
          return (
            {
              plan_price_can: plan.plan_price_can,
              plan_price_us: plan.plan_price_us,
              description:plan?.description
            }
          );
        }))

        let dt=response?.data?.filter(fl=>fl?.plan_id==planId);
        setTimeout(()=>{
          setPlanPrice(userDetail?.userInfo?.country_id==2?dt[0].plan_price_can:dt[0]?.plan_price_us)
          setUpdatePrice(userDetail?.userInfo?.country_id==2?dt[0].plan_price_can:dt[0]?.plan_price_us)
        },1000)
      })

      if (test?.length > 0) {
        Object?.entries(test)?.map(([key, val]) => {
          if (userDetail?.userInfo?.country_id == 2) {
            setPlanPrice(val?.plan_price_can);
          } else {
            setPlanPrice(val?.plan_price_us);
          }
        })
      }
      sessionStorage.setItem('reloadCount', String(reloadCountMS + 1));
    } else {
      sessionStorage.removeItem('reloadCountMS');
    }

  },[]);


  const GoProceed = (e) => {
  
    e.preventDefault();
    let sum = 0
    Object.entries(includeplanList).map(([key, val]) => {
      if(mn?.toLocaleUpperCase()==message?.toLocaleUpperCase()){

      }else{
        if (userDetail?.userInfo?.country_id == 2) {
          sum = sum + val?.can_price
        } else {
          sum = sum + val?.us_price
        }
      }
    })
   let obj;
    PLAN_SELECTION?.getTaxByProvinces(userDetail?.userInfo?.zip_code).then(res=>{
    if(res && res?.count>0){
          let tax=((sum + parseFloat(planPrice))*res?.data[0]?.Final_tax)/100;
          let finaltax=tax?.toFixed(2);
          let afterTax=(sum + parseFloat(planPrice))+parseFloat(finaltax);
          let taxAdded=parseFloat(afterTax?.toFixed(2));
          setTaxInValue(taxAdded)
          localStorage.setItem('discAMT',taxAdded);
           obj = {
            plan_id: parseInt(planId),
            user_id: userDetail?.userInfo?.user_id,
            topup_plan_id: addIDS.toString(),
            tax: res?.data[0]?.Final_tax?res?.data[0]?.Final_tax:0,
            additional_fee: 0.00,
            billable_amt: parseFloat((sum + parseFloat(planPrice))?.toFixed(2)),
            total_billable_amt: taxAdded?.toFixed(2)
          }
          setTimeout(() => {
            PLAN_SELECTION.billingInformation(obj).then(res => {
              if (res && res.status === 200) {
                toast.success('Updated successfully', { autoClose: 500, position: toast.POSITION.TOP_RIGHT });
                setTimeout(() => {
                  window.location.href = "#/confirmation"
                }, 2000)
              }
            })
          }, 500);
         
    }else{
      localStorage.setItem('discAMT',(sum + parseFloat(planPrice)))
       obj = {
        plan_id: parseInt(planId),
        user_id: userDetail?.userInfo?.user_id,
        topup_plan_id: addIDS.toString(),
        tax: 0,
        additional_fee: 0.00,
        billable_amt: parseFloat((sum + parseFloat(planPrice)).toFixed(2)),
        total_billable_amt:  parseFloat((sum + parseFloat(planPrice)).toFixed(2))
      }
      PLAN_SELECTION.billingInformation(obj).then(res => {
        if (res && res.status === 200) {
          toast.success('Updated successfully', { autoClose: 500, position: toast.POSITION.TOP_RIGHT });
          setTimeout(() => {
            window.location.href = "#/confirmation"
          }, 2000)
        }
      })
    }
  })    
  }

  const topup = (id) => {
    const addonids = [];
    addonids.push(id);
    setaddIDS([...addIDS, addonids]);
    const newModuleList = availableplanList.filter((e, i) => e.module_id === id);
    setaddModuleList(prev => [...prev, newModuleList[0]]);
    // includeplanList?.push(newModuleList[0])
    setIncludePlanList(prev => [...prev, newModuleList[0]])
    let onlyPrice = userDetail?.userInfo?.country_id == 2 ? newModuleList[0]?.can_price : newModuleList[0]?.us_price;    
    let final = UpplanPrice != 0 ? parseFloat(UpplanPrice) + parseFloat(onlyPrice) : parseFloat(planPrice) + parseFloat(onlyPrice);
    let fadd=final.toFixed(2)
    setUpdatePrice(fadd)
    setavailablePlanList(availableplanList.filter((e) => e.module_id !== id));
    setTimeout(()=>{
    },500)
  }

  const removeAddModule = (id) => {
    setaddModuleList(addModuleList.filter((e) => e.module_id !== id));
    setIncludePlanList(includeplanList.filter((e) => e.module_id !== id));
    const refill = addModuleList.filter((e) => e.module_id === id)[0];
    let fl=addIDS.filter((e) => e != id);
    setaddIDS(addIDS.filter((e) => e != id));
    setavailablePlanList((prev) => [...prev, refill]);
    let onlyPrice = userDetail?.userInfo?.country_id == 2 ? refill?.can_price : refill?.us_price;
    let final = parseFloat(UpplanPrice) - parseFloat(onlyPrice);
    let fprice = final?.toFixed(2)
    setUpdatePrice(fprice)
  }

  const getModuleIncludedList = () => {
    return Object.entries(includeplanList).map(([key, val]) => (
        <div 
        className={availableplanList?.length<=0?'col-md-3  card-header':'col-md-6  card-header'}
        // className="col-md-6" 
        key={key}>
          <div class="card mb-4 shadow"><br />
            <img src={val?.image} width={225} height={140} id="set-plan-img" className='card-img-top border-bottom' bgColor='#f5f5f5' fgColor='#999999' />
            <div class=""><h5 style={{ padding: 9 }}>{val?.module_name}</h5></div>
            <div style={{ marginTop: '-20px' }}>
              <ul class="list-unstyled mt-3 pl-2 pr-2">
                <li>{val?.description}</li>
              </ul>
              <h6 class="pricing-card-title mb-2 mt-2">

                {(p_name?.toUpperCase()==mn?.toUpperCase() && val?.module_name?.toUpperCase()!=mn1?.toUpperCase())?"":<span>
                  {val?.module_name?.toUpperCase()=="MAIN"?"":<>
                  {userDetail?.userInfo?.country_id == 2 ? val?.can_price : val?.us_price} {userDetail?.userInfo?.country_id==2?"$ CAD":'$ USD'}
                  </>} 
                  </span>}
                {/* ${countryId===2?val?.can_price:val?.us_price} */}
              </h6>
              <h6>
                {val?.module_name?.toUpperCase()==mn?.toUpperCase()?<span>Included in plan</span>:''}
              
              </h6>
              {val?.module_name?.toUpperCase()==mn?.toUpperCase()}
              {p_name?.toUpperCase()==mn?.toUpperCase()?"":val?.module_name?.toUpperCase()==mn1?.toUpperCase()?"":<button type="button" class="btn btn-sm btn-danger text-uppercases" onClick={(e) => removeAddModule(val.module_id)}>Remove</button>}
              {}
              <br />
              <br />
            </div>
          </div>
        </div>
    ))
  }

  const getModuleAvailableList = () => {
    return Object.entries(availableplanList).map(([key, val]) => (
      // (addIDS.includes(val?.module_id) == false ? <>
        <div
         className="col-md-6 card-header" 
         
         key={val?.module_id}>
          <div class="card mb-4 shadow"><br />
            <img src={val?.image} width={225} height={140} id="set-plan-img" className='card-img-top border-bottom' bgColor='#f5f5f5' fgColor='#999999' />
            <div class=""><h5 style={{ padding: 8 }}>{val?.module_name}</h5></div>
            <div style={{ marginTop: '-20px' }}>
              <ul class="list-unstyled mt-3 pl-2 pr-2">
                <li>{val?.description}</li>
              </ul>
              <h6 class="pricing-card-title mb-2 mt-2">
              $ {userDetail?.userInfo?.country_id == 2 ? val?.can_price : val?.us_price} {userDetail?.userInfo?.country_id==2?"CAD":'USD'}
              </h6>
              <button type="button" class="btn btn-sm btn-primary text-uppercases" onClick={(e) => topup(val?.module_id)}>Add Module</button><br /><br />
            </div>
          </div>
        </div>
      // </> : console.log(''))
    ))
  }

  const getaddModuleIncludedList = () => {
    return Object.entries(addModuleList).map(([key, val]) => (
      <div className="col-md-6" key={val.module_id}>
        <div class="card mb-4 shadow"><br />
          <img src={val.image} width={225} height={140} id="set-plan-img" className='card-img-top border-bottom' bgColor='#f5f5f5' fgColor='#999999' />
          <div class="bg-white"><h5 style={{ padding: 8 }}>{val.module_name}</h5></div>
          <div class="">
            <ul class="list-unstyled">
              <li>{val.description}</li>
            </ul>
            <h6 class="pricing-card-title mb-2 mt-2">
            $ {userDetail?.userInfo?.country_id== 2 ? val.can_price : val.us_price} {userDetail?.userInfo?.country_id==2?"CAD":'USD'}
            </h6>
            <button type="button" class="btn btn-sm btn-danger text-uppercases" onClick={(e) => removeAddModule(val.module_id)}>Remove</button>
            <br />
            <br />
          </div>
        </div>
      </div>
    ))
  }

  return (
    <section className="section section-md-bottom-50 bg-gray-lighter novi-background bg-cover">
      <div className="container">
        <div className="row pt-4">
          <div className="col">
            <ul class="nav bg-white py-3 shadow-sm justify-content-center">
              <li class="nav-item">
                <Link class="nav-link active" to={'/plan-selection'}><i class="fa-solid fa-check rounded-check"></i> Choose your plan</Link>
              </li>
              <li class="nav-item">
                <Link class="nav-link disabled" to={'/'}> Select your modules</Link>
              </li>
              {/* <li class="nav-item">
                  <Link class="nav-link disabled" to={'/'}>Billing Information</Link>
              </li> */}
              <li class="nav-item">
                <Link class="nav-link disabled" to={'/'}>Confirmation &amp; Checkout</Link>
              </li>
            </ul>
          </div>
        </div>
        <div class="pricing-header px-3 py-3 md-5 pb-md-4 mx-auto text-center">
         
          <p className="lead"> <span style={{fontSize:23,fontFamily:'sans-serif'}}>{message} $ {UpplanPrice == 0 ? planPrice : UpplanPrice} {userDetail?.userInfo?.country_id==2?"CAD":'USD'} </span></p>
          {/* <h5 className="m-2">Current Total:${UpplanPrice == 0 ? planPrice : UpplanPrice} </h5> */}
          <p class="lead">
          {test[0]?.description}
            {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc eget eros arcu. Mauris ullamcorper eu neque ac hendrerit. Cras facilisis dictum ligula, a porttitor nisi imperdiet sit amet. */}
          </p>
          <h2>Module Selection</h2>
        </div>

        <div className="row">
          <div className="col-lg-6" > <h2 className="" style={{ fontSize: 20,float:'left' }}>{availableplanList?.length>0?<span >Available</span>:<span style={{marginLeft:availableplanList?.length<=0?540:98}}>Included</span>}</h2></div>
          <div className="col-lg-6" > <h2 className="" style={{ fontSize: 20,float:'left'}} >{availableplanList?.length<=0?<span style={{marginLeft:'-98px'}}></span>:<div style={{textAlign:'center'}}><span >Included</span></div>}</h2></div>
        </div>
        <div className="row align-items-center offset-top-20">
          <div className="col-md-6">
            {availableplanList?.length ?
              <>
                <div className="col-lg-12">
                  <div className="row">
                    {getModuleAvailableList()}
                  </div>
                </div>
              </>
              : <>
                <div className="col-lg-12">
                  {/* <h2 className="" style={{ fontSize: 18 }}> No Modules Available</h2> */}
                  <div className="row">
                    {/* {getModuleAvailableList()} */}
                  </div>
                </div>
              </>
            }
          </div>
          {includeplanList?.length>0?
          <div className={availableplanList?.length<=0?'col-md-12':'col-md-6'} style={{marginLeft:availableplanList?.length<=0?140:""}}>
          <>
                <div className="col-lg-12" >
                  <div className="row" >
                  {getModuleIncludedList()}
                  </div>
                </div>
              </>
            {/* <div className="">
             
            </div> */}
          </div>:""}
          
        </div><br/>

            {/* {
              addModuleList?.length > 0?<>
              <div className="row">
        <h2 className="" style={{ margin: 'auto',fontSize:22 }}>Add On Modules</h2>
        </div><br/>
        <div className="row card-header">
         {addModuleList?.length > 0 ?
              addModuleList?.map(val=>
              <div className="col-md-3">
              <div class="card mb-4 shadow"><br />
          <img src={val.image} width={225} height={140} id="set-plan-img" className='card-img-top border-bottom' bgColor='#f5f5f5' fgColor='#999999' />
          <div class=""><h5 style={{ padding: 8 }}>{val.module_name}</h5></div>
          <div class="p-2">
            <ul class="list-unstyled">
              <li>{val.description}</li>
            </ul>
            <h6 class="pricing-card-title mb-2 mt-2">
              {countryId == 2 ? val.can_price : val.us_price} {countryId==2?"CAD":'USD'} 
            </h6>
            <button type="button" class="btn btn-sm btn-danger text-uppercases" onClick={() => removeAddModule(val.module_id)}>Remove</button>
            <br />
            <br />
          </div>
        </div>
              </div>
          )
            
            :""}
         </div>
              </>:""
            } */}
        <div className="row justify-content-sm-center align-items-center offset-top-10">
          <div className="col">
            <Link to="/billing-information"><button className="btn btn-primary" onClick={GoProceed} >Proceed</button></Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ModuleSelection;
