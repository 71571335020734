import React from 'react'

function Footer() {
  return (
    <><br/><br/><br/><br/>
      <footer className="fixed-bottom">
        <div className="section-0">
          <div className="container"></div>
        </div>
        <div className="page-footer section-0 section-xs-15 novi-background bg-cover bg-gray-lighter">
          <div className="container">
            <div className="row row-fix justify-content-sm-center align-items-sm-center">
              <div className="col-sm-12 text-sm-center">
                <p className="rights" id="">
                  <span>DNI </span>
                  <span>©&nbsp;</span>
                  {/* <span className="copyright-year" /> */}
                  2025
                  <span>.&nbsp;</span>
                  Designed&nbsp;by&nbsp;
                  <a className="link-decoration-none text-hover-primary text-gray-light" id='dni-link'
                  href="https://www.tactionsoftware.com/" target={"_blank"}>Taction Software LLC</a>
                </p>
              </div>
              <div className="col-sm-5 offset-top-10 offset-xs-top-0 text-sm-right">
              </div>
            </div>
          </div>
        </div>
      </footer>
</>
  )
}

export default Footer