import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DummyImage } from "react-simple-placeholder-image";
import { toast } from "react-toastify";
import { AUTENTICATE_API } from "../../services/Api/authenticationApi";
import StripeCheckout from 'react-stripe-checkout';
import { SEARCH_API } from "../../services/Api/searching";
import { PLAN_SELECTION } from "../../services/Api/PlanselectionApi";
import moment from "moment";
// import Purchased from "../Purchased/Purchased";
// import { PLAN_SELECTION } from "../../services/Api/PlanselectionApi";
const Confirmation = (props) => {
  const reloadCount = Number(sessionStorage.getItem('reloadCount')) || 0;
  const [promoCode, setPromoCode] = useState("")
  let userDetail = JSON.parse(localStorage.getItem('setUser'));
  const [countryId, setcountryid] = useState();
  const [message, setMessage] = useState(0)
  const [promoId, setPromoId] = useState("")
  const [billingDetail, setBillingDetail] = useState("")
  const [discountAmount, setDiscountAmount] = useState(0)
  const [verifyPromo, setVerifyPromo] = useState("")
  const [taxAmt, setTaxAmt] = useState(0)
  const [planid, setplanId] = useState("")
  const [isDisabled, setIsDisabled] = useState(false)
  const [promoMsg, setPromoMsg] = useState('')
  const [promoFlag, setPromoFlag] = useState(false);
  const [descountDeffer, setDescountDeffer] = useState(0);
  const [selectedModule, setSelectedModuls] = useState("");
  const [selectedModuleId, setSelectedModulsId] = useState("");
  const [selectePrice, setSelectedModulPrice] = useState("")
  const [selectedTax, setSelectedTax] = useState(0);
  const [selectedCount, setSelectedTaxCount] = useState(0);
  const [promoflag, setPromoflag] = useState(false);
  const [subtotal, setSubtotal] = useState(0);
  const [hstAmount, setHstAmount] = useState(0);
  const [gstAmount, setGstAmount] = useState(0);
  const [pstAmount, setPstAmount] = useState(0);
  const [qstAmount, setQstAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [promoData, setPromoData] = useState(false);
  const isCADCountry = userDetail?.userInfo?.country_id == 2;
  const ann = 'Student'
  // const pKey = 'pk_test_51L8jKIHHBNPR960E7yEdqJSkx7cUbg06YEB4VgLkb4tX2z9e7UKPSydT8WDEAwIo7GT7ecx57md36cs1XWJXNkoO00L7AJRpQD';
  const pKey = 'pk_live_51P5BflHL8Pds5c9OUPMJ1OrU6b2W1fctNBSIT6YTXmeTGelEDMFTrx0Heke8NjtJ8HP1ImqghRGV9P4cic4tFrJh00SuBgv8Qf';
  const [product, setProduct] = useState({
    name: "",
    price: "",
    currency: "usd",
    user_id: userDetail?.userInfo?.user_id,
    billable_amt: ""
  });

  const getModules = (ids) => {
    SEARCH_API?.getModulesByIds(ids).then(res => {
      if (res && res?.data) {
        setSelectedModuls(res?.data?.map(val => {
          return val?.module_name
        }));
        setSelectedModulsId(res?.data?.map(val => {
          return val?.module_id
        }));
        setSelectedModulPrice(res?.data)
      }
    });
  }

  const BillingDetail = () => {

    AUTENTICATE_API.billingDetail(userDetail?.userInfo?.user_id).then((response) => {
      if (response && response?.data) {
        console.log(response?.data);
        setBillingDetail(response?.data);
        getModules(response?.data?.topup_plan_id);
        PLAN_SELECTION?.getTaxByProvinces(userDetail?.userInfo?.zip_code).then(res => {
          setSelectedTaxCount(res?.count)
          if (res && res?.count > 0) {
            setSelectedTax(res?.data[0])
            setTaxAmt(res?.data[0]?.Final_tax);
            let billableAmount = response?.data?.billable_amt || 0;
            let taxPercentage = res?.data[0]?.Final_tax || 0;
            let taxAmount = (billableAmount * taxPercentage) / 100;
            setDescountDeffer(taxAmount.toFixed(2));
            if (userDetail?.userInfo?.country_id == 1) {
              setProduct({
                price: response?.data?.billable_amt?.toFixed(2),
                name: response?.data.plan_name,
                user_id: userDetail?.userInfo?.user_id,
                currency: userDetail?.userInfo?.country_id == 2 ? "cad" : 'usd',
                billable_amt: response?.data?.billable_amt
              })
            }

            setTimeout(() => {
              if (res?.count < 1) {
                setProduct({
                  price: response?.data?.billable_amt,
                  name: response?.data.plan_name,
                  user_id: userDetail?.userInfo?.user_id,
                  currency: userDetail?.userInfo?.country_id == 2 ? "cad" : 'usd',
                  billable_amt: response?.data?.billable_amt
                })
                // localStorage.setItem('discAMT', response?.data?.billable_amt)
              } else {
                let tax = (response?.data?.billable_amt * res?.data[0]?.Final_tax) / 100;
                let finaltax = tax?.toFixed(2);
                let afterTax = response?.data?.billable_amt + parseFloat(finaltax);


                let billableAmount = response?.data?.billable_amt || 0;
                let taxPercentage = res?.data[0]?.Final_tax || 0;
                let taxAmount = (billableAmount * taxPercentage) / 100;
                setDescountDeffer(taxAmount.toFixed(2));
                // setDescountDeffer(parseFloat(finaltax))
                let taxAdded = parseFloat(afterTax?.toFixed(2))
                setProduct({
                  price: taxAdded,
                  name: response?.data.plan_name,
                  user_id: userDetail?.userInfo?.user_id,
                  currency: userDetail?.userInfo?.country_id == 2 ? "cad" : 'usd',
                  billable_amt: response?.data?.billable_amt
                })
                // localStorage.setItem('discAMT', taxAdded)
              }
            }, 1000)
          } else {
            setProduct({
              price: response?.data?.billable_amt?.toFixed(2),
              name: response?.data.plan_name,
              user_id: userDetail?.userInfo?.user_id,
              currency: userDetail?.userInfo?.country_id == 2 ? "cad" : 'usd',
              billable_amt: response?.data?.billable_amt
            })
          }
        })
      }
    })
  }


  const removePromo = () => {


    AUTENTICATE_API.getPromoCode(promoCode).then(res => {
      if (res?.status == 200) {
        setMessage(0);
        setIsDisabled(false);
        setVerifyPromo(res?.data);

        // Update billing details and discount amount
        let descAmt = (billingDetail?.billable_amt + (userDetail?.userInfo?.country_id == 1 ? res?.data?.discountpromo?.amount_for_us : res?.data?.discountpromo?.amount_for_can));
        let afDesc = descAmt?.toFixed(2);

        let vals = localStorage.getItem('discAMT');

        setDiscountAmount(parseFloat(vals));

        let obj = {
          user_id: userDetail?.userInfo?.user_id,
          promo_id: promoId,
          discount: 0,
          total_billable_amt: parseFloat(localStorage.getItem('discAMT')),
          plan_name: billingDetail?.plan_name,
          tax: taxAmt,
          promoFlag: 1
        }
        AUTENTICATE_API.applyPromoCode(obj).then(res => {
          if (res?.status === 200) {
            setPromoflag(false);
            calculateHST();
            calculateGST();
            calculatePST();
            calculateQST();
          }
        });
        setPromoflag(false);

        BillingDetail();

      } else {
        setMessage(2);
      }
    });
  }
  const calculateHST = () => {

    if (!isNaN(selectedTax?.HST) && selectedTax?.HST !== 0) {
      const billableAmount = parseFloat(billingDetail?.billable_amt) - parseFloat(promoData);
      const calculatedHst = ((billableAmount.toFixed(2)) * selectedTax?.HST) / 100;
      setHstAmount(calculatedHst.toFixed(2));

    }
  };
  const calculateGST = () => {
    if (!isNaN(selectedTax?.GST) && selectedTax?.GST !== 0) {
      const billableAmount = parseFloat(billingDetail?.billable_amt) - parseFloat(promoData);
      const calculatedGst = ((billableAmount.toFixed(2)) * selectedTax?.GST) / 100;
      setGstAmount(calculatedGst.toFixed(2));

    }
  };

  const calculateQST = () => {
    if (!isNaN(selectedTax?.QST) && selectedTax?.QST !== 0) {
      const billableAmount = parseFloat(billingDetail?.billable_amt) - parseFloat(promoData);
      const calculatedQst = ((billableAmount.toFixed(2)) * selectedTax?.QST) / 100;
      setQstAmount(calculatedQst.toFixed(2));

    }
  };
  const calculatePST = () => {
    if (!isNaN(selectedTax?.PST) && selectedTax?.PST != 0) {
      const billableAmount = parseFloat(billingDetail?.billable_amt) - parseFloat(promoData);
      const calculatedPst = ((billableAmount.toFixed(2)) * selectedTax?.PST) / 100;
      setPstAmount(calculatedPst.toFixed(2));

    }
  };




  useEffect(() => {
    BillingDetail();

    if (reloadCount < 1) {
      window.location.reload();
      sessionStorage.setItem('reloadCount', String(reloadCount + 1));
      setcountryid(userDetail?.userInfo?.country_id);
    } else {
      sessionStorage.removeItem('reloadCount');
    }
  }, []);
  const changeHandler = (e) => {
    setPromoCode(e.target.value);
  }

  // const addPromo = () => {

  //   AUTENTICATE_API.getPromoCode(promoCode).then(res => {
  //     if (res?.success === true) {
  //       debugger
  //       setMessage(1);
  //       setPromoId(res?.data?.promo_id);
  //       setIsDisabled(true)
  //       setVerifyPromo(res?.data);
  //       setPromoId(res?.data?.promo_id);
  //       if (res?.data?.discountpromo?.discount_type == "Amount") {
  //         calculateHST();
  //         calculateGST();
  //         calculatePST();
  //         calculateQST();
  //         let descAmt = (product?.price - (userDetail?.userInfo?.country_id == 1 ? res?.data?.discountpromo?.amount_for_us : res?.data?.discountpromo?.amount_for_can));
  //         let afDesc = descAmt?.toFixed(2)
  //         setPromoMsg(userDetail?.userInfo?.country_id == 1 ? '$' + res?.data?.discountpromo?.amount_for_us : '$' + res?.data?.discountpromo?.amount_for_can);
  //         setPromoData(userDetail?.userInfo?.country_id == 1 ? res?.data?.discountpromo?.amount_for_us : res?.data?.discountpromo?.amount_for_can)
  //         setDiscountAmount(parseFloat(afDesc));
  //         console.log(discountAmount, "discountAmount")
  //         // setProduct({price:afDesc<0.50.50?0:parseFloat(afDesc)})
  //         let obj = {
  //           user_id: userDetail?.userInfo?.user_id,
  //           promo_id: res?.data?.promo_id,
  //           discount: userDetail?.userInfo?.country_id == 1 ? res?.data?.discountpromo?.amount_for_us : res?.data?.discountpromo?.amount_for_can,
  //           total_billable_amt: parseFloat(afDesc),
  //           plan_name: billingDetail?.plan_name,
  //           tax: taxAmt,
  //           promoFlag: 0
  //         }
  //         AUTENTICATE_API.applyPromoCode(obj).then(res => {
  //           if (res?.status === 200) {
  //             setPromoflag(true)
  //           }
  //         })
  //       }
  //       if (res?.data?.discountpromo?.discount_type == "Percentage") {
  //         calculateHST();
  //         calculateGST();
  //         calculatePST();
  //         calculateQST();
  //         let promoPer = (product?.price * res?.data?.discountpromo?.discount_amt) / 100
  //         let promoFix = (promoPer?.toFixed(2));
  //         let mainPercent = product?.price - parseFloat(promoFix);
  //         let lastPrice = mainPercent.toFixed(2)
  //         setPromoMsg(res?.data?.discountpromo?.discount_amt + '%');
  //         setPromoData((product?.billable_amt * res?.data?.discountpromo?.discount_amt) / 100)
  //         setDiscountAmount(parseFloat(lastPrice));
  //         console.log(discountAmount, "discountAmount")
  //         //  setProduct({price:lastPrice<0.50?0:parseFloat(lastPrice)})
  //         let obj = {
  //           user_id: userDetail?.userInfo?.user_id,
  //           promo_id: res?.data?.promo_id,
  //           discount: res?.data?.discountpromo?.discount_amt,
  //           total_billable_amt: parseFloat(lastPrice),
  //           plan_name: billingDetail?.plan_name,
  //           tax: taxAmt,
  //           promoFlag: 0
  //         }
  //         AUTENTICATE_API.applyPromoCode(obj).then(res => {
  //           if (res?.status === 200) {
  //             setPromoflag(true)
  //           }
  //         })

  //         setDiscountAmount(parseFloat(discountedTotal.toFixed(2)));
  //         localStorage.setItem('discAMT', discountedTotal);
  //         BillingDetail();
  //         calculateHST();
  //         calculateGST();
  //         calculatePST();
  //         calculateQST();
  //       }

  //     } else {
  //       setMessage(2)
  //     }
  //   })
  // }
  const addPromo = () => {
    AUTENTICATE_API.getPromoCode(promoCode).then(res => {
      if (res?.success === true) {

        // Check if the current plan is 'Student'
        if (billingDetail?.plan_name == 'Student') {
          // Convert the promo code to uppercase for uniformity
          const promoCode = res?.data?.promo_code?.toUpperCase();

          // Check if the promo code is exactly 'USI202408999' or starts with 'STU'
          // if (promoCode !== 'USI202408999' && !promoCode.startsWith('STU')) {
          //   setMessage(2); // Set invalid promo message
          //   return; // Exit the function if invalid
          // }


          // Check if the promo_type is not 'Student'
          if (res?.data?.promo_type != 'Student') {
            setMessage(2); // Set invalid promo message if promo_type is not 'Student'
            return; // Exit the function if invalid
          }
        }
        // Check if the current plan is 'Main' and the promo_type is 'Student'
        if (billingDetail?.plan_name == 'Main' && res?.data?.promo_type == 'Student') {
          setMessage(2); // Set invalid promo message if promo_type is 'Student' and plan is 'Main'
          return; // Exit the function if invalid
        }

        // Existing logic for valid promo code
        setMessage(1);
        setPromoId(res?.data?.promo_id);
        setIsDisabled(true);
        setVerifyPromo(res?.data);
        setPromoId(res?.data?.promo_id);

        if (res?.data?.discountpromo?.discount_type == "Amount") {
          calculateHST();
          calculateGST();
          calculatePST();
          calculateQST();
          let descAmt = (product?.price - (userDetail?.userInfo?.country_id == 1 ? res?.data?.discountpromo?.amount_for_us : res?.data?.discountpromo?.amount_for_can));
          let afDesc = descAmt?.toFixed(2);
          setPromoMsg(userDetail?.userInfo?.country_id == 1 ? '$' + res?.data?.discountpromo?.amount_for_us : '$' + res?.data?.discountpromo?.amount_for_can);
          setPromoData(userDetail?.userInfo?.country_id == 1 ? res?.data?.discountpromo?.amount_for_us : res?.data?.discountpromo?.amount_for_can);
          setDiscountAmount(parseFloat(afDesc));
          console.log(discountAmount, "discountAmount");

          let obj = {
            user_id: userDetail?.userInfo?.user_id,
            promo_id: res?.data?.promo_id,
            discount: userDetail?.userInfo?.country_id == 1 ? res?.data?.discountpromo?.amount_for_us : res?.data?.discountpromo?.amount_for_can,
            total_billable_amt: parseFloat(afDesc),
            plan_name: billingDetail?.plan_name,
            tax: taxAmt,
            promoFlag: 0
          };

          AUTENTICATE_API.applyPromoCode(obj).then(res => {
            if (res?.status === 200) {
              setPromoflag(true);
            }
          });
        }

        // Percentage discount logic
        if (res?.data?.discountpromo?.discount_type == "Percentage") {
          calculateHST();
          calculateGST();
          calculatePST();
          calculateQST();
          let promoPer = (product?.price * res?.data?.discountpromo?.discount_amt) / 100;
          let promoFix = promoPer?.toFixed(2);
          let mainPercent = product?.price - parseFloat(promoFix);
          let lastPrice = mainPercent.toFixed(2);
          setPromoMsg(res?.data?.discountpromo?.discount_amt + '%');
          setPromoData((product?.billable_amt * res?.data?.discountpromo?.discount_amt) / 100);
          setDiscountAmount(parseFloat(lastPrice));
          console.log(discountAmount, "discountAmount");

          let obj = {
            user_id: userDetail?.userInfo?.user_id,
            promo_id: res?.data?.promo_id,
            discount: res?.data?.discountpromo?.discount_amt,
            total_billable_amt: parseFloat(lastPrice),
            plan_name: billingDetail?.plan_name,
            tax: taxAmt,
            promoFlag: 0
          };

          AUTENTICATE_API.applyPromoCode(obj).then(res => {
            if (res?.status === 200) {
              setPromoflag(true);
            }
          });

          setDiscountAmount(parseFloat(discountedTotal?.toFixed(2)));
          localStorage.setItem('discAMT', discountedTotal);
          BillingDetail();
          calculateHST();
          calculateGST();
          calculatePST();
          calculateQST();
        }
      } else {
        setMessage(2); // Set invalid promo message if promo not valid
      }
    });
  };



  const makePayment = (token) => {
debugger

    product.price = discountAmount != 0 ? parseFloat(discountAmount) : parseFloat(product.price);
    if (selectedModule != "") {
      let mn = product.name;
      let sm = mn + `(${selectedModule?.toString()})`
      product.name = sm;
    }



    let expDate = "";

    var dt = new Date();
    var d = moment(dt).format('YYYY-MM-D HH:mm:ss');
    var tm = d?.split(" ");
    var dts = tm[0];
    var tms = tm[1];
    var dArray = dts.split('-')
    var yr = dArray[0];
    var month = dArray[1];
    var day = dArray[2];

    expDate = yr + "-" + month + "-" + day + ` ${tms}`;

    if (billingDetail?.plan_type == 2) {
      yr = 1 + parseInt(yr)
      expDate = yr + "-" + month + "-" + day + ` ${tms}`;
    } else {
      month = 1 + parseInt(month)
      if (month?.length == 1) {
      } else {
        month = month < 10 ? "0" + (month) : month
        expDate = yr + "-" + month + "-" + day + ` ${tms}`;
      }
    }

    product.expiry_dt = expDate;
    let data = {
      token,
      product,
    }
    debugger


    AUTENTICATE_API.makePayment(data).then(res => {
      // debugger
      console.log(res, "makePayment");
      if (res?.status === 200) {
        toast.success('Payment successfull Done', { autoClose: 1000, position: toast.POSITION.TOP_RIGHT });
        let storeData = localStorage.getItem('setUser');
        let parseData = JSON.parse(storeData);
        parseData['userInfo']["payment_status"] = 'succeeded'
        parseData['userInfo']["includeModule"] = '1'
        parseData['userInfo']["topudModule"] = selectedModuleId ? selectedModuleId.toString() : selectedModuleId
        localStorage.setItem("setUser", JSON.stringify(parseData))
        setTimeout(() => {
          window.location.href = '#/purchased';
        }, 500)
      }
    });
  }




  useEffect(() => {

    calculateHST();
    calculateGST();
    calculatePST();
    calculateQST();
  }, [billingDetail, discountAmount, descountDeffer, gstAmount, hstAmount, promoflag]);

  useEffect(() => {
    let calculatedTotal = 0;
    if (promoflag) {
      calculatedTotal = (
        parseFloat(billingDetail?.billable_amt) -
        parseFloat(promoData) +
        (parseFloat(gstAmount) + parseFloat(hstAmount) + parseFloat(pstAmount) + parseFloat(qstAmount))
      ).toFixed(2);
    } else {
      calculatedTotal = (
        parseFloat(billingDetail?.billable_amt) +
        parseFloat(descountDeffer)
      ).toFixed(2);
    }
    calculatedTotal = Math.max(parseFloat(calculatedTotal), 0);
    setTotalAmount(parseFloat(calculatedTotal));
  }, [billingDetail, discountAmount, descountDeffer, gstAmount, hstAmount, promoflag]);


  const handleCheckout = (token) => {
    console.log(token)
    debugger
    if ((totalAmount * 100) <= 0) {

      product.price = discountAmount != 0 ? parseFloat(discountAmount) : parseFloat(product.price);
      if (selectedModule != "") {
        let mn = product.name;
        let sm = mn + `(${selectedModule?.toString()})`
        product.name = sm;
      }



      let expDate = "";

      var dt = new Date();
      var d = moment(dt).format('YYYY-MM-D HH:mm:ss');
      var tm = d?.split(" ");
      var dts = tm[0];
      var tms = tm[1];
      var dArray = dts.split('-')
      var yr = dArray[0];
      var month = dArray[1];
      var day = dArray[2];

      expDate = yr + "-" + month + "-" + day + ` ${tms}`;

      if (billingDetail?.plan_type == 2) {
        yr = 1 + parseInt(yr)
        expDate = yr + "-" + month + "-" + day + ` ${tms}`;
      } else {
        month = 1 + parseInt(month)
        if (month?.length == 1) {
        } else {
          month = month < 10 ? "0" + (month) : month
          expDate = yr + "-" + month + "-" + day + ` ${tms}`;
        }
      }

      product.expiry_dt = expDate;

      let data = {
        product: {
          user_id: userDetail?.userInfo?.user_id,
          price: 0,
          email_id: userDetail?.userInfo?.email_id,
          name: product?.name,
          currency: product?.currency,
          expiry_dt: expDate
        },
        token:{}
      };
      console.log(data)

      // return





      AUTENTICATE_API.makePayment(data).then(res => {
        console.log(res, "handleCheckout");
        if (res?.status === 200) {
          toast.success('Payment successfull Done', { autoClose: 1000, position: toast.POSITION.TOP_RIGHT });
          let storeData = localStorage.getItem('setUser');
          let parseData = JSON.parse(storeData);
          parseData['userInfo']["payment_status"] = 'succeeded'
          parseData['userInfo']["includeModule"] = '1'
          parseData['userInfo']["topudModule"] = selectedModuleId ? selectedModuleId.toString() : selectedModuleId
          localStorage.setItem("setUser", JSON.stringify(parseData))
          setTimeout(() => {
            window.location.href = '#/purchased';
          }, 500)
        }
      });
    } else {
      toast.error('Please contact your academic program for valid promo code');
    }
  };


  return (
    <section className="section section-md-bottom-50 bg-gray-lighter novi-background bg-cover">
      <div className="container">
        <div className="row pt-4">
          <div className="col">
            <ul className="nav bg-white py-3 shadow-sm justify-content-center">
              <li className="nav-item">
                <Link className="nav-link active" to={'/plan-selection'}><i className="fa-solid fa-check rounded-check"></i> Choose your plan</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link active" to={`/module-selection/${billingDetail?.plan_id}`}><i className="fa-solid fa-check rounded-check"></i> Select your modules</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link disabled" to={'/'}>Confirmation &amp; Checkout</Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="row justify-content-sm-center bg-white my-3 mx-auto h-100">
          <div className="col-lg-5 d-flex flex-column contact-bg justify-content-end p-5">
            <div className="text-left">
              <h2 className="mb-3 text-white">{billingDetail?.plan_name}</h2>
              <p className="text-big font-weight-bold text-primary m-0" >${billingDetail?.billable_amt?.toFixed(2)}<small>{billingDetail?.plan_type == 2 ? <>/Year</> : <>/Mo</>}</small></p>
              <span style={{ fontSize: 14, fontWeight: 'bold' }}>{selectedModule?.toString()}</span>
            </div>
          </div>
          <div className='col-lg-7 p-5'>
            <h2 className="mb-4" style={{ marginTop: '-30px' }}>Purchase Summary</h2>
            <div className="row">
              <div className="col-md-3" style={{ color: 'black', textAlign: 'initial', paddingBottom: 15 }}><span>{billingDetail?.plan_name} plan</span></div>
              <div className="col-md-3"></div>
              <div className="col-md-3"></div>
              <div className="col-md-3" style={{ color: 'black', textAlign: 'right' }}>${userDetail?.userInfo?.country_id == 2 ? <span>{billingDetail?.plan_price_can}</span> : <span>{billingDetail?.plan_price_us}</span>} {userDetail?.userInfo?.country_id == 2 ? "CAD" : "USD"}</div>
            </div>
            {selectePrice?.length > 0 ? selectePrice?.map((val, i) => <div className="row" key={i}>
              <div className="col-md-3" style={{ color: 'black', textAlign: 'initial', paddingBottom: 15 }}><span>{val?.module_name}</span></div>
              <div className="col-md-3"></div>
              <div className="col-md-3"></div>
              <div className="col-md-3" style={{ color: 'black', textAlign: 'right' }}>${userDetail?.userInfo?.country_id == 2 ? val?.can_price : val?.us_price} {userDetail?.userInfo?.country_id == 2 ? "CAD" : "USD"}</div>
            </div>) : ''}
            <div className="row" style={{ borderTop: '1px solid black', borderBottom: '1px solid black' }}>
              <div className="col-md-5" style={{ color: 'black', textAlign: 'initial' }}><p style={{ marginTop: 9 }}>{isCADCountry ? (
                <p style={{ marginTop: 9 }}>
                  {/* A) Subtotal {promoflag ? <span style={{ color: 'grey' }}>(After Discount {promoMsg})</span> : ''} */}
                  A) Subtotal {promoflag ? <span style={{ color: 'grey' }}>(Promo Code Applied)</span> : ''}
                </p>
              ) : (
                <p style={{ marginTop: 9 }}>
                  {/* Subtotal {promoflag ? <span style={{ color: 'grey' }}>(After Discount {promoMsg})</span> : ''} */}
                  Subtotal {promoflag ? <span style={{ color: 'grey' }}>(Promo Code Applied)</span> : ''}
                </p>
              )} </p></div>
              <div className="col-md-2"></div>
              <div className="col-md-2"></div>
              <div className="col-md-3" style={{ color: 'black', textAlign: 'right', marginTop: 9 }}>
                ${promoflag ? Math.max(parseFloat(billingDetail?.billable_amt - promoData).toFixed(2), 0) : parseFloat(billingDetail?.billable_amt).toFixed(2)} {userDetail?.userInfo?.country_id === 2 ? "CAD" : "USD"}
              </div>
            </div><br />



            {
              (selectedCount !== 0 && selectedTax?.GST != 0) ? <>
                <div className="row">
                  <div className="col-md-3" style={{ color: 'black', textAlign: 'initial', paddingBottom: 15 }}><span>{selectedTax?.GST != 0 ? 'GST' : ''} ({selectedTax?.GST != 0 ? selectedTax?.GST : ''} %)</span></div>
                  <div className="col-md-3"></div>
                  <div className="col-md-3"></div>
                  <div className="col-md-3" style={{ color: 'black', textAlign: 'right' }}> ${promoflag ? Math.max(gstAmount, 0).toFixed(2) : Math.max(((billingDetail?.billable_amt * selectedTax?.GST) / 100)?.toFixed(2), 0)} {userDetail?.userInfo?.country_id == 2 ? 'CAD' : 'USD'}
                  </div>
                </div>
              </> : ''
            }

            {selectedCount !== 0 && selectedTax?.HST !== 0 && (
              <div className="row">
                <div className="col-md-3" style={{ color: 'black', textAlign: 'initial', paddingBottom: 15 }}>
                  <span>{selectedTax?.HST !== 0 ? `HST (${selectedTax?.HST} %)` : ''}</span>
                </div>
                <div className="col-md-3"></div>
                <div className="col-md-3"></div>
                <div className="col-md-3" style={{ color: 'black', textAlign: 'right' }}>
                  ${promoflag ? Math.max(hstAmount, 0).toFixed(2) : Math.max(((billingDetail?.billable_amt * selectedTax?.HST) / 100)?.toFixed(2), 0)} {userDetail?.userInfo?.country_id == 2 ? 'CAD' : 'USD'}
                </div>
              </div>
            )}
            {selectedCount !== 0 && selectedTax?.PST !== 0 && (
              <div className="row">
                <div className="col-md-3" style={{ color: 'black', textAlign: 'initial', paddingBottom: 15 }}>
                  <span>{selectedTax?.PST !== 0 ? `PST (${selectedTax?.PST} %)` : ''}</span>
                </div>
                <div className="col-md-3"></div>
                <div className="col-md-3"></div>
                <div className="col-md-3" style={{ color: 'black', textAlign: 'right' }}>
                  ${promoflag ? Math.max(pstAmount, 0).toFixed(2) : Math.max(((billingDetail?.billable_amt * selectedTax?.PST) / 100)?.toFixed(2), 0)} {userDetail?.userInfo?.country_id == 2 ? 'CAD' : 'USD'}
                </div>
              </div>
            )}
            {selectedCount !== 0 && selectedTax?.QST !== 0 && (
              <div className="row">
                <div className="col-md-3" style={{ color: 'black', textAlign: 'initial', paddingBottom: 15 }}>
                  <span>{selectedTax?.QST !== 0 ? `QST (${selectedTax?.QST} %)` : ''}</span>
                </div>
                <div className="col-md-3"></div>
                <div className="col-md-3"></div>
                <div className="col-md-3" style={{ color: 'black', textAlign: 'right' }}>
                  ${promoflag ? Math.max(qstAmount, 0).toFixed(2) : Math.max(((billingDetail?.billable_amt * selectedTax?.QST) / 100)?.toFixed(2), 0)} {userDetail?.userInfo?.country_id == 2 ? 'CAD' : 'USD'}
                </div>
              </div>
            )}
            {
              (selectedCount != 0 && selectedTax?.Final_tax != 0) ? <>
                <div className="row" style={{ borderTop: '1px solid black', borderBottom: '1px solid black' }}>
                  <div className="col-md-4" style={{ color: 'black', textAlign: 'initial' }}><p style={{ marginTop: 9 }}>{selectedTax?.Final_tax != 0 ? <span>B) Total Tax ({selectedTax?.Final_tax} %)</span> : ''}</p></div>
                  <div className="col-md-2"></div>
                  <div className="col-md-3"></div>
                  <div className="col-md-3" style={{ color: 'black', textAlign: 'right', marginTop: 9 }}>${promoflag ? Math.max(parseFloat(gstAmount) + parseFloat(hstAmount) + parseFloat(pstAmount) + parseFloat(qstAmount), 0).toFixed(2) : Math.max(descountDeffer, 0)} {userDetail?.userInfo?.country_id == 2 ? "CAD" : "USD"}
                  </div>
                </div>
                <br />
              </> : ''
            }


            <div className="row">
              <div className="col-md-1" style={{ color: 'black', textAlign: 'initial', paddingBottom: 15 }}>Promo</div>
              <div className="col-md-5"><input className="form-input" onChange={changeHandler} style={{ height: 15, width: 200 }} type="text" name="promocode" placeholder="Enter Promo" /></div>
              <div className="col-md-1">
                {
                  message == 1 ? <span style={{ color: 'green', fontWeight: 'bold' }}>
                    <button id='promo-buttons-remove' onClick={removePromo}>Remove</button>
                  </span> : message == 2 ? <span id="danger-promo">Invalid</span> : ''
                }
              </div>
              {/* <div className="col-md-5" style={{ color: 'black' }}>
                {
                  !isDisabled ?
                    <button id='promo-buttons' onClick={addPromo}>Apply</button>
                    : (
                      billingDetail?.plan_name == 'Student' ? (
                        parseFloat(promoMsg.replace('$', '')) <= 0 ?
                          <div style={{ color: '#6f746f', textAlign: 'initial', marginLeft: '60px' }}>
                            <span>Promo code applied.</span>
                          </div>
                          :
                          <div style={{ color: '#6f746f', textAlign: 'initial', marginLeft: '60px' }}>
                            <span>You got {promoMsg} discount on this promo code.</span>
                          </div>
                      ) : (
                        <div style={{ color: '#6f746f', textAlign: 'initial', marginLeft: '60px' }}>
                          <span>You got {promoMsg} discount on this promo code.</span>
                        </div>
                      )
                    )
                }
              </div> */}
              <div className="col-md-5" style={{ color: 'black' }}>
                {
                  !isDisabled ? (
                    <button id='promo-buttons' onClick={addPromo}>Apply</button>
                  ) : (
                    billingDetail?.plan_name == 'Student' ? (
                      <div style={{ color: '#6f746f', textAlign: 'initial', marginLeft: '60px' }}>
                        <span>Student promo code applied successfully.</span>
                      </div>
                    ) : billingDetail?.plan_name == 'Main' ? (
                      <div style={{ color: '#6f746f', textAlign: 'initial', marginLeft: '60px' }}>
                        <span>Promo code applied successfully.</span>
                      </div>
                    ) : (
                      <div style={{ color: '#6f746f', textAlign: 'initial', marginLeft: '60px' }}>
                        <span>Promo code applied successfully</span>
                      </div>
                    )
                  )
                }
              </div>



            </div>
            <div className="row mt-2" style={{ borderTop: '1px solid gray', borderBottom: '1px solid gray' }}>
              <div className="col-md-3" style={{ color: 'black', textAlign: 'initial' }}>
                <p className="mt-2">{isCADCountry ? 'Total Amount (A+B)' : 'Total Amount'}</p>
              </div>
              <div className="col-md-9" style={{ color: 'black', textAlign: 'right' }}>
                <p className="mt-2">
                  ${promoflag ?
                    (
                      Math.max(
                        parseFloat(
                          (
                            parseFloat(billingDetail?.billable_amt) -
                            parseFloat(promoData) +
                            (parseFloat(gstAmount) + parseFloat(hstAmount) + parseFloat(pstAmount) + parseFloat(qstAmount))
                          ).toFixed(2)
                        ),
                        0
                      ).toFixed(2)
                    ) :
                    (
                      Math.max(
                        parseFloat(
                          (
                            parseFloat(billingDetail?.billable_amt) +
                            parseFloat(descountDeffer)
                          ).toFixed(2)
                        ),
                        0
                      ).toFixed(2)
                    )
                  }
                  {userDetail?.userInfo?.country_id == 2 ? "CAD" : "USD"}
                </p>
              </div>
            </div>


            <div className="col-sm-12 offset-top-10">

              {(totalAmount * 100) <= 0 ? (
                <button
                  className="btn btn-primary btn-block"
                  onClick={()=>handleCheckout(makePayment)}
                >
                  Checkout ${totalAmount}{userDetail?.userInfo?.country_id == 2 ? "CAD" : "USD"}
                </button>
              ) : (
                verifyPromo || billingDetail?.plan_id == 1 || (discountAmount || product?.price) < 0.50 ? (
                  <StripeCheckout
                    stripeKey={pKey}
                    token={makePayment}
                    name="Make Payment"
                    amount={(totalAmount) * 100}
                    currency="USD"
                    email={userDetail?.userInfo?.email_id}
                    price={billingDetail?.billable_amt}
                  >
                    <button className="btn btn-primary btn-block">
                      Checkout ${totalAmount}{userDetail?.userInfo?.country_id == 2 ? "CAD" : "USD"}
                    </button>
                  </StripeCheckout>
                ) : (
                  <button
                    className="btn btn-primary btn-block"
                    onClick={() => toast.error('Please contact your academic program for valid promo code')}
                  >
                    Checkout ${totalAmount} {userDetail?.userInfo?.country_id == 2 ? "CAD" : "USD"}
                  </button>
                )
              )}


            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Confirmation;
